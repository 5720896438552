import React from 'react'
import Header1 from '../Components/Header/Header1'
import Header2 from '../Components/Header/Header2'
import CartPage from '../Components/Cartpage'
import Footer from '../Components/Footer'
import Order from '../Components/Order'

const Orderpage = () => {
  return (
    <div className='pt-[50px] md:pt-[90px]'>
      <Header1/>
      {/* <Header2/> */}
      <Order/>
      <Footer/>
    </div>
  )
}

export default Orderpage


import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './Pages/Homepage';
import Categorypage from './Pages/Categorypage';
import Productpage from './Pages/Productpage';
import Storepage from './Pages/Storepage';
import Cartpage from './Pages/Cartpage';
import Billingpage from './Pages/Billingpage';
import Confirmationpage from './Pages/Confirmationpage';
import Login from './Components/Login';
import Signup from './Components/SignUp';
import ScrollToTop from './Components/ScrollToTop';
import Orderpage from './Pages/Orderpage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  return (
    <div className="bg-[#181818] w-full overflow-hidden">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/category/:id" element={<Categorypage />} />
          <Route path="/product" element={<Productpage />} />
          <Route path="/store" element={<Storepage />} />
          <Route path="/cart" element={<Cartpage />} />
          <Route path="/billing" element={<Billingpage />} />
          <Route path="/confirmation" element={<Confirmationpage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/order" element={<Orderpage />} />
        </Routes>
        {/* Add PhoneModal here */}
        
      </Router>
    </div>
  );
}

export default App;

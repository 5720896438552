

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Spinner from './Spinner';

const ProductRecipe = () => {
  const [product, setProduct] = useState(null);
  const [recipes, setRecipes] = useState([]);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const productId = query.get('productId');

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (!productId) return;
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/product/${productId}`);
        setProduct(data.product);
        setRecipes(data.product.recipes || []);
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };

    fetchProductDetails();
  }, [productId]);


  if (!product) {
    return <div><Spinner /></div>;
  }

  // Check if product.description or any recipe image is null
  const hasRecipeImages = recipes.every(recipe => recipe.image?.url); 
  if (!product.description || !hasRecipeImages) {
    return null; // Hide the component if either is missing
  }


  

  return (
    <div className="bg-[#181818] -mt-12 md:mt-0  pb-[40px] px-4 md:px-0 md:flex md:flex-col md:justify-center md:items-center">
      <div className="mx-0 md:mx-16">
        <h2 className="text-center text-white text-[28px] md:text-[44px] md:px-[30px] font-semibold mb-[20px] w-full px-4 md:px-0">
          {product.recipesTitle}
        </h2>
        <div className="mx-auto border-t border-dashed border-[#ECBC56] w-32 md:w-[640px] mb-[20px]"></div>
        {/* <p className="text-center text-white text-[18px] max-w-auto mx-auto mb-[30px] md:text-[32px]">
         {product.superDescription}
        </p> */}

        {/* Flex container for recipes, enabling horizontal scroll on mobile */}
        <div className="mx-1 md:mx-12 rounded-lg flex overflow-x-auto md:overflow-hidden snap-x snap-mandatory gap-4 scrollbar-hide md:item-center">
          {recipes.map((recipe, index) => (
            <div
              key={index}
              className="flex-none snap-start w-full md:w-1/3 h-auto relative"
            >
              <img
                src={recipe.image.url}
                alt={`Recipe Step ${index + 1}`}
                className="rounded-lg w-full h-[200px] md:h-[250px]"
              />
              <div className="absolute top-2 left-2 bg-[#ECBC56] text-white font-semibold rounded-[12px] px-[10px] py-[8px]">
                Step {index + 1}
              </div>
              <div className="text-white mt-2 text-left">
                <p>{recipe.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductRecipe;

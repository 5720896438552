import React, { useRef }  from 'react'
import Header1 from '../Components/Header/Header1'
import Header2 from '../Components/Header/Header2'
import ProductComponent from '../Components/ProductComponent'
import Footer from '../Components/Footer'

const Storepage = () => {
  const aboutUsRef = useRef(null); // Create a ref for the AboutUs section

  const scrollToAboutUs = () => {
    aboutUsRef.current?.scrollIntoView({ behavior: 'smooth',block: 'start' }); // Smooth scroll to AboutUs section
  };
// Create a ref for the AboutUs section

  const scrollToStore = () => {
    aboutUsRef.current?.scrollIntoView({ behavior: 'smooth',block: 'start' }); // Smooth scroll to AboutUs section
  };

  return (
    <div className='pt-[110px]'>
        <Header1/>
        {/* <Header2/> */}
        <ProductComponent  scrollToStore/> 
        <Footer  scrollToAboutUs={scrollToAboutUs}  />
      
    </div>
  )
}

export default Storepage

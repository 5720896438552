import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from './Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSnackbar } from 'notistack';
import HighlightedFeatures from '../Components/HighlightedFeatures';

const ProductDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [error, setError] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [selectedWeight, setSelectedWeight] = useState(null); // State for selected weight
  const navigate = useNavigate();
  const location = useLocation();
  const [addingToCart, setAddingToCart] = useState(false); // State to track if adding to cart



  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get('productId');

  const token = localStorage.getItem('authToken');


  useEffect(() => {

    
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/product/${productId}`);
        if (response.data) {
          const fetchedProduct = response.data.product;
          setProduct(fetchedProduct);
          setSelectedImage(fetchedProduct.images[0].url);
          setSelectedWeight(fetchedProduct.availableWeights[0]); // Set default weight if available

          // Fetch the category name using the categoryId
          if (fetchedProduct.category) {
            fetchCategoryName(fetchedProduct.category);
          }

        } else {
          setError('No product found');
        }
        setLoading(false);
      } catch (err) {

        setLoading(false);
      }
    };


    const fetchCategoryName = async (categoryId) => {
      try {
        // Fetch all categories
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/categories`);

        if (response.data && response.data.categories) {
          // Find the category with the matching ID
          const matchedCategory = response.data.categories.find(category => category._id === categoryId);

          if (matchedCategory) {
            setCategoryName(matchedCategory.title); // Assuming the category object has a name field
            setCategoryDescription(matchedCategory.subtitle || "");
          } else {
            setCategoryName('Unknown '); // Handle case where category isn't found
            setCategoryDescription('');
          }
        } else {
          setCategoryName('Unknown '); // Handle case where categories aren't found in the response
          setCategoryDescription('');
        }
      } catch (err) {

        setCategoryName('Unknown ');
        setCategoryDescription('');
      }
    };


    if (productId) {
      fetchProduct();
    }
  }, [productId]);


  const handleQuantityChange = (event) => {
    setSelectedQuantity(event.target.value);
  };

  const handleWeightChange = (weightOption) => {
    setSelectedWeight(weightOption); // Update selected weight

  };



  const handleAddToCart = async () => {


    if (token) {
      try {
        setAddingToCart(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cart`, {
          productId: product._id,
          name: product.name,
          quantity: selectedQuantity,
          weight: selectedWeight.weight,  // Send selected weight
          price: selectedWeight.price,      // Send price associated with weight
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        toast.success(`${product.name} has been added to your cart!`);


        return true;
      } catch (error) {
        console.error('Failed to add product to cart:', error.response?.data || error);
        toast.error('Could not add product to cart. Please try again.');
      } finally {
        setAddingToCart(false);
      }
    } else {
      setAddingToCart(true);
      // Prepare the new cart item
      const newItem = {
        _id: product._id,
        product: product._id,
        name: product.name,
        quantity: selectedQuantity,
        weight: selectedWeight.weight,
        price: selectedWeight.price,
        image: product.images[0].url,
      };

      // Check if 'cartItems' exists in localStorage
      const existingCartItems = JSON.parse(localStorage.getItem('cartItems'));

      if (existingCartItems) {
        // 'cartItems' exists, so add the item by refering the _id
        // const existingCartItem = existingCartItems.find((item) => item._id === newItem._id);
        const existingCartItem = existingCartItems.find((item) => item._id === newItem._id && item.weight === selectedWeight.weight);

        if (existingCartItem) {

          existingCartItem.quantity += newItem.quantity;

        } else {
          existingCartItems.push(newItem);
        }
        localStorage.setItem('cartItems', JSON.stringify(existingCartItems));
      } else {
        // 'cartItems' does not exist, create a new array with the new item
        localStorage.setItem('cartItems', JSON.stringify([newItem]));
      }

      setAddingToCart(false);
      toast.success(`${product.name} has been added to your cart!`);

      return true;
    }
  };





  const handleBuyNowClick = async (e) => {
    e.stopPropagation();
    const success = await handleAddToCart();
    if (success) {
      navigate('/cart'); // Navigate to cart page after successful addition
    }
  };



  const formatDescription = (description) => {
  
    const lines = description.split('\\n').map(line => line.trim()).filter(line => line !== ' ') || description.split('\n').map(line => line.trim()).filter(line => line !== ' ')
  
  
    return lines.map((line, index) => (
      <p key={index}>{line}</p>
    ));
  };
  


  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!product) {
    return <p>No product found</p>;
  }
 
  return (
    <>
      <div className="bg-[#181818] py-[30px] px-[20px] md:px-[80px] relative flex flex-col items-center">
        <ToastContainer
          position="top-right"  // Ensures it stays in the top-right corner
          autoClose={2000}      // Adjust the auto-close time if needed
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{
            backgroundColor: '#ECBE56',
            color: 'black',
            display: 'flex',
            width: 'auto',    // Allows full message display
            padding: '10px',
          }}
          progressStyle={{
            backgroundColor: 'black',
          }}
          style={{
            position: 'fixed',  // Fixed positioning to keep it outside the card
            top: '100px',        // Distance from the top of the page
            right: '40px',      // Distance from the right of the page
            zIndex: 9999,       // Ensures it appears above other elements
          }}
        />

        <h2 className="text-center text-white text-[28px] md:text-[40px] font-semibold mb-[15px] mt-10 md:mt-[12px]">
          {categoryName}
        </h2>
        <div className="mx-auto border-dashed border-t-[1px] border-[#ECBC56] w-[230px] md:w-[441px] mb-[15px] "></div>
        <p className="text-center text-white text-[16px] md:text-[32px] mb-[20px]">
          {categoryDescription}
        </p>

        <div className="rounded-lg flex flex-col md:flex-row md:items-start md:gap-[60px]">
          <div className="w-full md:w-[614px] ">
            <img
              src={selectedImage} // Use selectedImage state
              alt={product.name}
              className="rounded-lg w-full h-[280px] md:h-[500px] object-cover"
            />
            <div className="mt-6 flex overflow-x-auto gap-[6px] items-start scrollbar-hide md:grid md:grid-cols-4 md:gap-1 cursor-pointer">
              {product.images.map((image, index) => ( // Map through images array
                <img
                  key={index}
                  src={image.url} // Use the image URL from the array
                  alt={`Product image ${index + 1}`}
                  className="w-[142px] h-[65px] rounded-[10px] object-cover flex-shrink-0"
                  onClick={() => setSelectedImage(image.url)} // Update selected image on click
                />
              ))}
            </div>
          </div>


          <div className="w-full md:w-[626px] flex flex-col gap-4 md:gap-[20px] mt-8 md:mt-0">
            <div className="py-2 font-semibold leading-none bg-clip-text bg-primarygradient text-transparent text-3xl md:text-5xl font-bold leading-none md:leading-[1] md:w-[469px]">
              {product.name}
            </div>
            <p className="text-gray-300 text-md md:text-xl">{formatDescription(product.description)}</p>

            <div className="flex items-center gap-[10px]">
              <div className="bg-[#ECBC56] text-[#000E21] font-bold px-2 py-1 h-[34px] rounded mr-2">
                {product.ratings} ★
              </div>
              <p className="text-gray-300 text-base md:text-lg">{product.numOfReviews} Ratings</p>
            </div>

            <div className="">
              <div>
                <p className="text-[20px] text-white font-semibold mb-2 ">
                  Price: ₹{selectedWeight ? selectedWeight.price : 'N/A'}
                </p>
              </div>
              <h3 className="text-gray-300 font-normal">Select Weight:</h3>
              <div className='flex flex-row items-center gap-2'>


                <div className="flex flex-row gap-2 mt-2 w-full">
                  {/* Replace select with visible options */}
                  {product.availableWeights.map((weightOption) => (
                    <button
                      key={weightOption.weight}
                      className={`p-2 rounded-lg ${selectedWeight?.weight === weightOption.weight ? 'text-white border-2 border-[#ECBC56] text-[#181818] bg-[#222222] font-medium' : 'bg-[#222222]'} text-white`}
                      onClick={() => handleWeightChange(weightOption)} // Update selected weight on click
                    >
                      {weightOption.weight}
                    </button>
                  ))}
                </div>
              </div>
            </div>


            {!selectedWeight || selectedWeight.weight !== 'Sample' ? ( // Check if weight is not 'sample'
              <div className="flex items-center gap-2">
                <h3
                  className="text-[14px] md:text-[16px] text-gray-300 font-normal rounded-l-lg"
                  htmlFor="quantity"
                >
                  Select Quantity:
                </h3>
                <div className="flex items-center ">
                  <button
                    onClick={() => setSelectedQuantity(prev => Math.max(1, prev - 1))} // Decrease quantity
                    className="py-1 px-6 bg-[#222222] text-white text-2xl rounded-l-lg hover:text-[#ECBC56] font-normal  "
                  >
                    -
                  </button>
                  <span className="py-2 px-4 bg-[#222222] text-white">{selectedQuantity}</span>
                  <button
                    onClick={() => setSelectedQuantity(prev => prev + 1)} // Increase quantity
                    className="py-1 px-6 bg-[#222222] text-white text-2xl rounded-r-lg hover:text-[#ECBC56]"
                  >
                    +
                  </button>
                </div>
              </div>
            ) : null}


            <div className="flex gap-[15px] flex-col md:flex-row justify-between w-full md:w-[412px] text-xl">
              <button
                className="text-[15px] md:text-[24px] w-full md:w-[196px] h-[49px] bg-[#181818] border-2 border-[#0071E3] text-[#0071E3] rounded-[5px] px-[20px] py-[10px] font-semibold hover:bg-gray-700 transition duration-200"
                onClick={handleAddToCart}
              >
                {addingToCart ? 'Adding...' : 'Add to Cart'}
              </button>
              <button
                onClick={handleBuyNowClick}
                className="text-[15px] md:text-[24px] w-full md:w-[196px] h-[49px] bg-[#0071E3] text-white rounded-[5px] px-[20px] py-[10px] font-semibold hover:bg-blue-900 transition duration-200"
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>

      </div>
      <div className=" bg-[#181818] md:px-12">
      <HighlightedFeatures categoryName={categoryName} />
      </div>
  
    </>


  );
};

export default ProductDetail;




import React from 'react';
import { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { FaWhatsapp, FaInstagram, FaFacebookF, FaLinkedin } from 'react-icons/fa';
import color from '../asstes/innerImage.png';


const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
};

const SocialMedia = () => {
    const windowWidth = useWindowWidth();
    const socialMedias = [
        { icon: <FaWhatsapp size={windowWidth < 600 ? 28 : 44} />, color: '#25D366', url: 'https://wa.me/message/A4KCBEWUL7Y6M1' },
        { icon: <FaInstagram size={windowWidth < 600 ? 28 : 44} />, color: '#E1306C', url: 'https://www.instagram.com/the.paarampariyaa?igsh=OGQ5ZDc2ODk2ZA==' },
        { icon: <FaLinkedin size={windowWidth < 600 ? 28 : 44} />, color: '#0E76A8', url: 'https://linkedin.com/company/paarampariyaa' },
        { icon: <FaFacebookF size={windowWidth < 600 ? 28 : 44} />, color: '#1877F2', url: 'https://www.facebook.com/profile.php?id=61556720781399' },
    ];
    return (
        <div className='bg-[#181818] px-5 md:px-20   py-[20px]'>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    maxWidth: '100%',
                    height: { xs: '200px', md: '350px' }, // Adjust height for mobile and desktop
                    overflow: 'hidden',
                    borderRadius: '20px',
                }}
            >
                {/* Background Image */}
                <Box
                    component="img"
                    src={color}
                    alt="background"
                    sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />

                {/* Overlay planes with icons */}
                {socialMedias.map((media, index) => (
                    <a href={media.url} target="_blank" rel="noopener noreferrer">
                        <Box
                            key={index}
                            sx={{
                                position: 'absolute',
                                top: `${0}%`, // Adjust top position for each icon
                                left: `${index * 25}%`, // Adjust left position for each icon
                                width: '25%', // Adjust width of each box
                                height: '100%', // Adjust height of each box
                                backgroundColor: media.color,
                                opacity: 0.5,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', // Creates a parallelogram
                                transition: 'transform 0.5s ease',
                                transform: 'scale(1)',
                                '&:hover': {
                                    transform: 'scale(1.0)', // Slight scaling on hover
                                    opacity: 0.8, // Increase opacity on hover
                                    cursor: 'pointer',
                                },
                            }}
                        >


                            <IconButton
                                sx={{
                                    color: 'white',
                                    fontSize: 48,
                                    opacity: 1, // Ensure the icon is fully visible
                                    position: 'relative', // Optional: can help in positioning if needed
                                    zIndex: 1,
                                }}
                            >
                                {media.icon}
                            </IconButton>

                        </Box>
                    </a>
                ))}
            </Box>
        </div>
    );
};

export default SocialMedia;

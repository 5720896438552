import React from 'react'
import Footer from '../Components/Footer';
import Header1 from '../Components/Header/Header1';
import Header2 from '../Components/Header/Header2';
import HighlightedFeatures from '../Components/HighlightedFeatures';
import ProductDetail from '../Components/ProductDetail';
import Reviews from '../Components/Reviews';
import StarRecipe from '../Components/steps';
import { features } from '../data/FeaturesData';
import { steps } from '../data/stepData';
import { otherRecipes } from '../data/recipesData';
import Banner from '../Components/Banner';
import innerImage from '../asstes/Promotional Section.png';
import SocialMedia from '../Components/SocialMedia';


const Productpage = () => {
  return (
    <div className=' md:pt-[80px] pt-[40px] '>
      <Header1/>
      {/* <Header2/> */}
      <ProductDetail path="/product/:productId" element={<ProductDetail />}/>
      {/* <HighlightedFeatures path="/products/:productId" element={<HighlightedFeatures />} /> */}
      <StarRecipe  path="/product/:productId/recipes" element={<StarRecipe />}  />
      <Banner/>
      <Reviews/>
      <SocialMedia />
      <Footer/>
    </div>
  )
}

export default Productpage

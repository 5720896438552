

import React from 'react';
// import flavour from "../assets/Group.png";
import flavour from "../asstes/Group.png"
import Natural from "../asstes/Frame 60.png"
import Premium from "../asstes/Frame 61.png"
import Health from "../asstes/Group 11.png"

const WhyChooseUs = () => {
  const features = [
    {
      title: "Pure and Safe Ingredients",
      description: "Our products are free from chemicals, additives, and preservatives for a healthier choice.",
      icon: "https://firebasestorage.googleapis.com/v0/b/paarampariya-1.appspot.com/o/Pure%20and%20safe.jpg?alt=media&token=631bb564-a034-4d41-b13e-c07f2802ce25",
    },
    {
      title: "Wholesome Nutrition",
      description: "Packed with nutrient-rich millets, our foods provide natural nourishment in every bite.",
      icon: "https://firebasestorage.googleapis.com/v0/b/paarampariya-1.appspot.com/o/Wholesome%20nutri.jpg?alt=media&token=b2581026-dfeb-41a5-961d-053d6a553bb2",
    },
    {
      title: "Crafted with Care",
      description: "We combine traditional methods with modern standards to create food that’s truly good for you.",
      icon: "https://firebasestorage.googleapis.com/v0/b/paarampariya-1.appspot.com/o/craft%20with%20care.jpg?alt=media&token=d6a27766-eb10-46db-a6c2-898312bdd876",
    },
    {
      title: "Commitment to Wellness",
      description: "PAARAMPARIYAA offers clean, sustainable products that promote a balanced and healthier lifestyle.",
      icon: "https://firebasestorage.googleapis.com/v0/b/paarampariya-1.appspot.com/o/commit%20to%20wellnes.jpg?alt=media&token=1548fc8a-68a8-47a0-ad75-d76e6e8f0468",
    },
  ];

  return (
    <section className="bg-[#181818] md:px-[80px] px-[20px] py-[20px]  text-white">
      <div className="container ">
        <h2 className="text-[28px] md:text-[44px]  font-semibold text-center mb-5 ">Why Choose Us?</h2>
        <div className=' mx-auto border-dashed border-t-2 border-[#ECBC56]  w-[230px] md:w-[500px] mb-[20px]'></div>
        <p className="text-center md:text-[32px] text-[12px] font-normal md:mb-[30px] mb-[30px]">
          Finest collection of organic millets, spices, and more delivered straight to your door.
        </p>
        <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[20px] mb-[20px]">
          {features.map((feature, index) => (
            <div
              key={index}
              className="px-[15px] py-[20px] bg-[#222222] rounded-[20px] flex flex-col items-center text-center  w-full h-[400px] md:h-[330px] md:w-[270px]  "
            >
              <img src={feature.icon} alt={feature.title} className="rounded-lg w-full h-2/3 mb-[25px]" /> {/* Use src attribute */}
              <h3 className="text-[20px]  font-semibold mb-[12px]">{feature.title}</h3>
              <p className='text-[15px] font-normal'>{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
// import React from 'react'
// import AboutUs from '../Components/AboutUs';
// import BestSellers from '../Components/BestSellers';
// import WhyChooseUs from '../Components/Features';
// import Footer from '../Components/Footer';
// import Header1 from '../Components/Header/Header1';
// import Header2 from '../Components/Header/Header2';
// import HeroSection from '../Components/HeroSection';
// import Testimonials from '../Components/Testimonial';
// import Banner from '../Components/Banner';
// import Reviews from '../Components/Reviews';

// function Homepage() {
//   return (
//     <div className='w-full'>
//        <Header1 />
//        {/* <Header2/> */}
//        <HeroSection/>
//       <WhyChooseUs/>
//       <Banner/>
//       <BestSellers/>
//       <AboutUs/>
//       <Testimonials/>
//       <Footer/>
    
//     </div>
//   )
// }

// export default Homepage
import React, { useRef } from 'react';
import AboutUs from '../Components/AboutUs';
import BestSellers from '../Components/BestSellers';
import WhyChooseUs from '../Components/Features';
import Footer from '../Components/Footer';
import Header1 from '../Components/Header/Header1';
import HeroSection from '../Components/HeroSection';
import Testimonials from '../Components/Testimonial';
import Banner from '../Components/Banner';
import SocialMedia from '../Components/SocialMedia';


function Homepage() {
  const aboutUsRef = useRef(null); // Create a ref for the AboutUs section

  const scrollToAboutUs = () => {
    aboutUsRef.current?.scrollIntoView({ behavior: 'smooth',block: 'start' }); // Smooth scroll to AboutUs section
  };

  return (
    <div className='w-full'>
      {/* <PhoneModal/> */}
      <Header1 />
      <HeroSection />
      <div id='aboutus'>
      <WhyChooseUs />
      </div>
    
      <Banner />
      <BestSellers />
      {/* Pass the ref to the AboutUs component */}
      <AboutUs ref={aboutUsRef} />
      <Testimonials />
      <SocialMedia/>
      {/* Pass the scrollToAboutUs function as a prop to Footer */}
      <Footer scrollToAboutUs={scrollToAboutUs} />
    </div>
  );
}

export default Homepage;

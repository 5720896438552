
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

// Arrow Button component
const ArrowButton = ({ direction = "right", onClick, isVisible }) => (
  <button
    onClick={onClick}
    className={`bg-white bg-opacity-90 text-gray-800 rounded-full p-2 shadow-md hover:shadow-lg transition duration-1000 ease-in-out
      ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
    style={{
      width: '40px',
      height: '40px',
      position: 'absolute',
      top: '65%',
      transform: 'translateY(-50%)',
      zIndex: 10,
      transition: 'opacity 0.3s ease', // Smooth transition for visibility
      [direction === "left" ? 'left' : 'right']: '16px'
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={`w-6 h-6 ${direction === "left" ? 'rotate-180' : ''}`} // Flip the arrow icon for the left direction
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 5l7 7-7 7"
      />
    </svg>
  </button>
);

const HighlightedFeatures = ({ categoryName }) => {
  const [product, setProduct] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get('productId');

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      if (productId) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/product/${productId}`);
          setProduct(response.data.product);
        } catch (error) {
          console.error('Error fetching product details:', error);
        }
      }
    };

    fetchProductDetails();
  }, [productId]);
 

  // Scroll handler for buttons
  const handleScroll = (direction) => {
    const scrollAmount = 450; // Adjust based on card width
    if (scrollContainerRef.current) {
      if (direction === "left") {
        scrollContainerRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  // Track scroll position to hide/show the buttons
  const checkScrollPosition = () => {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;

    // Check if at the start (hide left button)
    setIsAtStart(scrollLeft === 0);

    // Check if at the end (hide right button)
    setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
  };

  // Add event listener for scrolling
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', checkScrollPosition);

      return () => {
        scrollContainer.removeEventListener('scroll', checkScrollPosition);
      };
    }
  }, []);

  return (
    <div className="pt-4 pb-10 relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="text-center">
        <h2 className="text-[28px] font-bold text-white md:text-[44px] font-semibold">
          Highlighted Features
        </h2>
        <div className="mx-auto border-dashed border-t-[1px] border-[#ECBC56] w-[230px] md:w-[441px] mb-[20px] mt-[20px]"></div>
        <p className="text-white px-[20px] text-[18px] md:text-[32px] font-normal mt-2">
          Explore the essential {categoryName}
        </p>
      </div>

      {/* Arrow Buttons for Desktop */}
      <ArrowButton
        direction="left"

        onClick={() => handleScroll('left')}
        isVisible={isHovered && !isAtStart} // Show left button only if hovered and not at the start
      />
      <ArrowButton

        direction="right"
        onClick={() => handleScroll('right')}
        isVisible={isHovered && !isAtEnd} // Show right button only if hovered and not at the end
      />

      {/* Flex container for horizontal scrolling on mobile */}
      <div className="w-full ">
        <ArrowButton
          direction="left"

          onClick={() => handleScroll('left')}
          isVisible={isHovered && !isAtStart} // Show left button only if hovered and not at the start
        />
        <ArrowButton

          direction="right"
          onClick={() => handleScroll('right')}
          isVisible={isHovered && !isAtEnd} // Show right button only if hovered and not at the end
        />
        <div
          ref={scrollContainerRef}
          className="flex overflow-x-auto space-x-4 px-[20px] md:px-[80px] mt-8 scrollbar-hide"
          style={{ scrollPadding: '0 40px' }}
        >
          {product ? product.highlights.map((highlight, index) => (
            highlight.image && highlight.image.url ? (
              <div
                key={index}
                className="relative rounded-3xl overflow-hidden shadow-md  transition-transform duration-300 bg-cover bg-center w-full md:w-[350px] h-[474px] flex-shrink-0"
                style={{ backgroundImage: `url(${highlight.image.url})` }}
              >
                <div className="absolute inset-0"></div>
                <div className="relative z-10 p-4">
                  <h3 className="text-white text-[20px] font-normal">{highlight.label}</h3>
                  <h3 className="text-white text-[24px] font-semibold mt-2">{highlight.highlightDesc}</h3>
                </div>

              </div>
            ) : null
          )) : (
            <p className="text-white text-xl">No highlights available</p>
          )}

        </div>
        <h2 className='text-white -mt-10  text-center text-[70px] md:hidden'>...</h2>

      </div>
    </div>
  );
};

export default HighlightedFeatures;

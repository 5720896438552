import React from 'react';
import millet from '../asstes/Millet.png'; // Replace with your image path

const WhyOurProduct = () => {
  return (
    <div className="bg-[#181818] text-white py-[30px] md:px-[80px] px-[20px] ">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className=" text-[28px] md:text-[44px] font-semibold mb-[20px]">Why Our Products</h1>
        <div className=' mx-auto border-dashed border-t-2 border-[#ECBC56]  w-[230px] md:w-[500px] mb-[20px]'></div>
       
      </div>

      {/* Content Section */}
      <div className="mx-auto flex flex-col lg:grid-cols-2 gap-12">
        
        {/* Card 2 */}
       

        {/* Card 3 */}
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/paarampariya-1.appspot.com/o/pure%20and%20safe.jpg?alt=media&token=f376255a-e405-4ef2-a75a-5a1ec2f91f4d"
            alt="Millet"
            className="w-full md:w-[50%] h-[300px] md:h-[485px] rounded-[20px] object-cover"
          />
          <div className="w-full lg:w-1/2">
            <h2 className=" text-[22px] md:text-[40px] font-semibold text-yellow-300 mb-4">
            Pure and Safe Ingredients

            </h2>
            <p className="text-gray-300 text-[25px] font-normal">
            We believe in using only the finest, naturally sourced ingredients. With no artificial preservatives, colors, or additives, each product is crafted with purity in mind, giving you and your family the best of nature’s goodness. We believe you deserve to know exactly what goes into your food. That’s why we clearly list every ingredient we use, allowing you to make informed choices. Our promise of pure and safe ingredients means you’ll always know what you’re eating

            </p>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
        <img
            src="https://firebasestorage.googleapis.com/v0/b/paarampariya-1.appspot.com/o/Wholesome%20nutri.jpg?alt=media&token=b2581026-dfeb-41a5-961d-053d6a553bb2"
            alt="Millet"
            className="w-full md:w-[50%] h-[300px] md:h-[485px] rounded-[20px] object-cover  block md:hidden"
          />
          <div className="w-full lg:w-1/2">
            <h2 className=" text-[22px] md:text-[40px]  font-semibold text-yellow-300 mb-4">
            Wholesome Nutrition
            </h2>
            <p className="text-gray-300 text-[25px] font-normal">
            Each item is crafted to keep you feeling fuller and energized, making them a perfect choice for busy days and active lifestyles. With our products, you don’t have to choose between health and flavor. We bring together the best of both worlds, creating snacks that satisfy cravings without the guilt. Made from wholesome ingredients, they’re designed to fuel your body while still tasting delicious. Enjoy Products that not only tastes great but also supports your health goals.

            </p>
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/paarampariya-1.appspot.com/o/Wholesome%20nutri.jpg?alt=media&token=b2581026-dfeb-41a5-961d-053d6a553bb2"
            alt="Millet"
            className="w-full md:w-[50%] h-[300px] md:h-[485px] rounded-[20px] object-cover block hidden md:block"
          />
        </div>
        <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/paarampariya-1.appspot.com/o/Bringing%20Tradition%20to%20Your%20Table.jpg?alt=media&token=b0ff0938-eaa8-4b18-8bff-2520aadb1180"
            alt="Millet"
            className="w-full md:w-[50%] h-[300px] md:h-[485px] rounded-[20px] object-cover"
          />
          <div className="w-full lg:w-1/2">
            <h2 className=" text-[22px] md:text-[40px] font-semibold text-yellow-300 mb-4">
            Bringing Tradition to Your Table

            </h2>
            <p className="text-gray-300 text-[25px] font-normal">
            Our products are inspired by traditional recipes passed down through generations, bringing the rich flavors and nutrition of our heritage to your table. We blend the wisdom of the past with the convenience of today, so you can enjoy authentic tastes rooted in culture. Each product is crafted to reflect the flavors and ingredients that have been cherished for centuries. We believe in the power of traditional foods to bring families together. Our products carry the essence of classic flavors that are both familiar and comforting, perfect for sharing with loved ones and building new memories around timeless tastes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyOurProduct;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import {authConfig} from './authConfig.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <KindeProvider
    domain={authConfig.domain}
    clientId={authConfig.clientId}
    redirectUri={authConfig.redirectUri}
    logoutRedirectUri={authConfig.logoutRedirectUri}
  >
    <App />
  </KindeProvider>
);

reportWebVitals();

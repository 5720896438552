import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductCard from './ProductCard'; 
import Spinner from './Spinner'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OurProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const {categoryId}= location.state || {}; // Get the category name from state
  const {categoryName}= location.state || {}; 
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/products/all`);
        const allProducts = response.data.products;
        allProducts.sort((a, b) => a.sortNumber - b.sortNumber);
        
        // Filter products based on the category name
        const filteredProducts = allProducts.filter(
          (product) => product.category === categoryId
        );
        
        setProducts(filteredProducts); // Update the state with filtered products
        setLoading(false); // Set loading to false
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    if (categoryId) {
      fetchProducts(); // Fetch products when a category is selected
    }
  }, [categoryId]);
 

  // Set the default selected weight for each product to the first available weight
  const [selectedWeights, setSelectedWeights] = useState(
    products.reduce((acc, product) => ({
      ...acc,
      [product._id]: product.availableWeights[0]?.weight || ''
    }), {})
  );

  const handleWeightChange = (productId, weightOption) => {
    setSelectedWeights((prevWeights) => ({
      ...prevWeights,
      [productId]: weightOption
    }));
  };

  const handleProductClick = (productId) => {
    navigate(`/product?productId=${productId}`); // Navigate to /product/{id} when a product is clicked
  };

  return (
    <div className="bg-[#181818] justify-center text-white  px-[20px] md:px-[160px]">
       <style>
        {`
          @keyframes translateYEffect {
            0% {
              transform: translateY(90px);
              opacity: 0;
            }
            100% {
              transform: translateY(10);
              opacity: 1;
            }
          }
        `}
      </style>
      <ToastContainer
        position="top-right"  // Ensures it stays in the top-right corner
        autoClose={2000}      // Adjust the auto-close time if needed
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#ECBE56',
          color: 'black',
          display: 'flex',
          width: 'auto',    // Allows full message display
          padding: '10px',
        }}
        progressStyle={{
          backgroundColor: 'black',
        }}
        style={{
          position: 'fixed',  // Fixed positioning to keep it outside the card
          top: '100px',        // Distance from the top of the page
          right: '40px',      // Distance from the right of the page
          zIndex: 9999,       // Ensures it appears above other elements
        }}
      />

      {/* <div className="mx-auto">
        <div className="text-center mb-12">
          <h1 className=" font-semibold mb-2 text-[23px] md:text-[40px] md:pt-[50px]">
            Choose Our Products from {categoryName || "Millets"}
          </h1>
          <div className="mx-auto border-dashed border-t-[1px] border-[#ECBC56] w-[230px] md:w-[441px] mb-[20px]"></div>
          <p className="text-gray-400 text-[16px] font-normal">
            Explore the essential {categoryName} that capture the authentic flavors of Indian cuisine in every dish.
          </p>
        </div>

        <div className=" md:px-[190px] h-auto grid  grid-cols-1 md:grid-cols-3  justify-center gap-12 ">
          {loading ? (
            <Spinner />
          ) : products.length > 0 ? (
            products.map((product,productIndex) => (
              <div
              className="snap-start"
              key={product._id}
              style={{
                animation: `translateYEffect 1.5s ease forwards`,
                animationDelay: `${productIndex * 0.1}s`,
                opacity: 0,
              }}
            >
              <ProductCard
                key={product._id}
                product={product}
                selectedWeight={selectedWeights[product._id]} // Pass selected weight for each product
                onWeightChange={(weightOption) => handleWeightChange(product._id, weightOption)}
                onProductClick={() => handleProductClick(product._id)} // Pass the productId
              />
              </div>
            ))
          ) : (
            <p className="p-0  col-span-3  w-full text-xl justify-center text-center">No products found for this category.</p>
          )}
        </div>
      </div> */}
      <div className="mx-auto">
        <div className="text-center mb-12">
          <h1 className="font-semibold mb-2 text-[23px] md:text-[40px] md:pt-[20px] mb-[20px]">
            Choose Our Products from {categoryName || ""}
          </h1>
          <div className="mx-auto border-dashed border-t-[1px] border-[#ECBC56] w-[230px] md:w-[441px] mb-[20px]"></div>
         
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-[60px] ">
          {loading ? (
            <Spinner />
          ) : products.length > 0 ? (
            products.map((product, productIndex) => (
              <div
                className=" md:px-[33px] md:py-[10px] mb-8  border-2 border-transparent rounded-[20px] bg-[#222222] text-white relative shadow-[0_10px_30px_rgba(0,0,0,0.8)] hover:shadow-[0_15px_40px_rgba(0,0,0,1)] transition-transform transition-shadow duration-300 transform hover:scale-105 cursor-pointer"
                key={product._id}
               
                onClick={() => handleProductClick(product._id)}
                 // Navigate to product details
              >
                 <h2 className="text-[20px] px-[15px] md:px-[0px] md:text-[36px] pt-[10px] md:pt-[0px] font-semibold text-center gap-[10px]">{product.name}</h2>
                <p className="text-[14px] px-[15px] md:px-[0px] md:text-[25px] text-center pt-[5px] md:pt-[0px]">{product.superDescription}</p>
                <div className="flex flex-row md:flex-row gap-[10px] pt-[25px] px-[15px] md:px-[0px] items-center justify-center">
                  <button
                    className="flex items-center justify-center bg-[#0071E3] text-white md:w-[195px] md:h-[39px] w-[159px] h-[39px] text-[#041423] py-[10px] px-[20px] rounded-[5px] text-[16px] hover:bg-blue-900"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleProductClick(product._id); // Navigate to product details
                    }}
                  >
                    Explore
                  </button>
                  <button
                    className="flex items-center justify-center md:w-[195px] md:h-[39px] w-[159px] h-[39px] bg-[#181818] text-[#0071E3] font-bold py-2 px-4 text-[16px] border-2 rounded-[5px] border-[#0071E3] hover:bg-gray-700"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate('/store', { state: { categoryName: categoryName } });
                    }}
                    >
                      Buy Now
                    </button>
                  </div>
                <div className="flex justify-center items-center  md:pt-[38px] pt-[30px] pb-4 mb-[20px]">
                  <img
                    className="rounded-lg h-[276px] w-full md:w-full md:h-[400px] px-5 md:px-0 object-cover object-center"
                    src={product.images[0]?.url || ''} 
                    alt={product.title}
                  />
                </div>
               
                </div>
              ))
            ) : (
              <p className="p-0 col-span-2 w-full text-xl justify-center text-center">No products found for this category.</p>
            )}
          </div>
        </div>

    </div>
  );
};

export default OurProducts;


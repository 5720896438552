import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from '../asstes/paarampariyaa_logo.png';
import copyRights from '../asstes/copyrights.png';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [navItems, setNavItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch categories and products
  const fetchCategoriesAndProducts = async () => {
    try {
      setIsLoading(true);
      const categoriesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/categories`);

      const fetchedCategories = categoriesResponse.data.categories.map(category => ({
        id: category._id,
        name: category.title,
      }));

      setNavItems(fetchedCategories);
    } catch (error) {
      console.error("Error fetching categories and products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Effect to fetch data on mount
  useEffect(() => {
    fetchCategoriesAndProducts();
  }, []);

  const handleNavigation = (path, sectionId) => {
    if (location.pathname === path) {
      if (sectionId) {
        const element = document.getElementById(sectionId);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      navigate(path, { state: { scrollTo: sectionId } });
    }
  };

  // Handle category click and navigate
  const handleCategoryClick = (id, name) => {
    navigate(`/category/${id}`, { state: { categoryId: id, categoryName: name } });
  };

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  return (
    <>
      <footer className="bg-[#000] border-t-2 border-[#ECBC56] text-white px-[5%] md:px-[1%] pt-8 pb-2" id="footer">
        {/* Bottom Section with Links */}
        <div className="flex flex-col md:flex-row justify-between md:items-start md:text-left gap-[40px] md:gap-2 lg:px-24">
          {/* Logo and About Section */}
          <div className="mb-8">
            <div className="mb-[20px] -mt-4">
              <img src={Logo} className="h-[80px] items-center justify-center md:h-20 w-auto mb-6" alt="Logo" />
            </div>
            <p className="md:pl-4 text-[20px] md:text-[20px] font-normal w-[300px] h-[50px] md:h-[40px]">
              Join us on a flavorful journey and explore our spices and bring the taste of tradition to your table.
            </p>
          </div>

            {/* Products Section */}
            <div className="mb-6">
              <h2 className="text-md md:text-[25px] font-semibold mb-4">Catergories</h2>
              <ul className="space-y-[12px]">
                {isLoading ? (
                  <li></li>
                ) : (
                  navItems.map((category) => (
                    <li key={category.id}>
                      <button onClick={() => handleCategoryClick(category.id, category.name)} className="focus:outline-none">
                        {category.name}
                      </button>
                    </li>
                  ))
                )}
              </ul>
            </div>

          
   
            {/* Quick Links Section */}
            <div className="mb-6">
              <h2 className="text-md md:text-[25px] font-semibold mb-4">Quick Links</h2>
              <ul className="space-y-[12px]">
                <li>
                  <Link to="/" onClick={() => handleNavigation('/', null)}>Home</Link>
                </li>
                <li>
                  <Link to="/store" onClick={() => handleNavigation('/store', null)}>Store</Link>
                </li>
                <li>
                  <button onClick={() => {
                    const footer = document.getElementById('aboutus');
                    if (footer) {
                      const targetPosition = footer.offsetTop;
                      const startPosition = window.scrollY;
                      const distance = targetPosition - startPosition - 100;
                      const duration = 2000; // Duration in milliseconds
                      let startTime = null;

                      function animationScroll(currentTime) {
                        if (!startTime) startTime = currentTime;
                        const timeElapsed = currentTime - startTime;
                        const run = ease(timeElapsed, startPosition, distance, duration);
                        window.scrollTo(0, run);
                        if (timeElapsed < duration) requestAnimationFrame(animationScroll);
                      }

                      function ease(t, b, c, d) {
                        t /= d / 2;
                        if (t < 1) return (c / 2) * t * t + b;
                        t--;
                        return (-c / 2) * (t * (t - 2) - 1) + b;
                      }

                      requestAnimationFrame(animationScroll);
                    }
                  }} className="focus:outline-none">
                    About Us
                  </button>
                </li>
              </ul>
            </div>

          {/* Contact Info Section */}
          <div>
            <h2 className="text-md md:text-[25px] font-semibold mb-4">Contact Us</h2>
            <ul className="space-y-[12px]">
              {/* <li className="w-full md:w-2/3">
              No. 42 Pillaiyar Kovil street,
              Arni, Tamil Nadu - 632301
              </li> */}
              <li>
                <a href="mailto:thepaarampariyaa@gmail.com" className="">
                thepaarampariyaa@gmail.com
                </a>
              </li>
              {/* <li>
                <a href="tel:+919998887770" className="">
                  +91 999 888 7770
                </a>
              </li> */}
              <li>
                <a href="https://wa.me/message/A4KCBEWUL7Y6M1" target="_blank" rel="noopener noreferrer" className="">
                  Whatsapp
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/the.paarampariyaa?igsh=OGQ5ZDc2ODk2ZA==" target="_blank" rel="noopener noreferrer" className="">
                  Instagram
                </a>
              </li>
                 
              <li>
                <a href="https://linkedin.com/company/paarampariyaa" target="_blank" rel="noopener noreferrer" className="">
                  Linkedin
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61556720781399" target="_blank" rel="noopener noreferrer" className="">
                  Facebook
                </a>
              </li>
              
           
            </ul>
          </div>

        </div>

        <div className="mt-8">

          <div className="mb-2 flex text-[14px] md:text-sm  items-center justify-center md:space-x-5 ">
            <Link className="hover:text-[#ECBC56]" onClick={() => openModal('Terms and Conditions Content')}>Terms and Conditions</Link>
            <Link className="hover:text-[#ECBC56]" onClick={() => openModal('Return Policy Content')}>Return Policy</Link>
            <Link className="hover:text-[#ECBC56]" onClick={() => openModal('Privacy Policy Content')}>Privacy Policy</Link>
            <Link className="hover:text-[#ECBC56]" onClick={() => openModal('Cancellation Policy Content')}>Cancellation policy</Link>
          </div>
          <div className="flex flex-col items-center md:flex-row gap-2 items-end justify-center p-1">

            <p className="text-center text-[16px] md:text-sm">Copyrights © Paarampariyaa 2024.</p>
            <p className="text-center text-[16px] md:text-sm">Powered by Daat Studios</p>
            {/* <img src={copyRights} alt="Logo" className="-ml-2 -mb-1 h-10 w-auto " /> */}

            {/* <div className="flex items-end justify-center">
              <p className="text-center text-[16px] md:text-sm">Powered by</p>
              <img src={copyRights} alt="Logo" className="h-12 w-auto " />
            </div> */}
          </div>

        </div>
      </footer>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-transparent bg-opacity-50 px-4 md:px-0 mt-16 md:m-16 md:mt-32    z-10">
          <div className="bg-[#222222] md:p-5 border border-[#ECBC56] rounded shadow-lg relative">
            <button onClick={closeModal} className="absolute top-4 right-4 text-lg text-[#ECBC56] ">✖</button>
            <div className="text-white text-sm md:text-lg">
              {modalContent && ( // Ensure modalContent is defined
                <>
                  {modalContent === 'Terms and Conditions Content' && (
                    <div className="md:px-6 px-6   py-12" >
                      <h2 className="text-[#ECBC56] text-lg font-bold pb-4">Terms and Conditions</h2>
                      <div className="max-h-[40vh] overflow-y-auto">
                        <p>1.1. Introduction: These terms and conditions govern the use of our online payment system for purchasing our products. By making a payment, you agree to comply with these terms.</p>
                        <p>1.3. Product Dispatch: Once payment is confirmed, we will process and dispatch your order within [4-8] business days. You will receive a confirmation email/SMS with tracking details upon dispatch.</p>
                        <p>1.4. No Return Policy: Please note that we do not accept returns after products have been dispatched. Ensure that your order is correct before completing your payment.</p>
                        <p>1.5. Cancellations: Cancellations are accepted only before the product is dispatched. Once the product has been dispatched, cancellations will no longer be possible.</p>
                        <p>1.6. Limitation of Liability: We are not liable for any issues related to failed transactions or technical errors during payment. If unauthorized transactions occur, please contact our customer service immediately.</p>
                      </div>
                    </div>

                  )}
                  {modalContent === 'Return Policy Content' && (
                    <div className="px-6 py-12" >
                      <h2 className="text-[#ECBC56] text-lg font-bold pb-4">Return Policy</h2>
                      <div className="max-h-[40vh] overflow-y-auto">
                        <p>3.1. No Returns Accepted: Due to the nature of our products, we do not accept returns from customers. Once the product has been dispatched, the sale is final.</p>
                      </div>
                    </div>
                  )}

                  {modalContent === 'Privacy Policy Content' && (
                    <div className="px-6 py-12">
                      <h2 className="text-[#ECBC56] text-lg font-bold pb-4">Privacy Policy</h2>
                      <div className="max-h-[40vh] overflow-y-auto">
                        <p>2.1. Information Collection: We collect personal information such as your name, contact details, and payment information to process transactions and fulfill orders.</p>
                        <p>2.2. Use of Information: The information collected will be used exclusively to process payments, fulfill orders, and improve our customer service.</p>
                        <p>2.3. Data Protection: We use industry-standard encryption and security protocols to ensure that your payment details are protected during online transactions.</p>
                        <p>2.4. Third-Party Disclosure: We do not share your personal data with third parties except as necessary to complete payments and process orders.</p>
                        <p>2.5. User Rights: You have the right to access, modify, or request deletion of your personal information at any time. For assistance, contact [Customer Support Email/Phone].</p>
                      </div>
                    </div>
                  )}
                  {modalContent === 'Cancellation Policy Content' && (
                    <div className="px-6 py-12">
                      <h2 className="text-[#ECBC56] text-lg font-bold pb-4">Refund and Cancellation Policy</h2>
                      <div className="max-h-[40vh] overflow-y-auto">
                        <p>4.1. Cancellation Policy: Cancellations are accepted only if the request is made before the product is dispatched. If the order has already been dispatched, cancellation requests will not be accepted.</p>
                        <p>4.2. Refunds for Cancellations: If you cancel your order before dispatch, we will issue a full refund to your original payment method. Refunds will be processed within [7-12] business days of receiving the cancellation request.</p>
                        <p>4.3. Refunds Before Dispatch: If for any reason we are unable to fulfill your order before dispatch, a full refund will be initiated, and you will be notified via email/SMS.</p>
                        <p>4.4. Non-Refundable Items: Since returns are not accepted, refunds will only be processed for cancellations made before dispatch or for orders we cannot fulfill.</p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
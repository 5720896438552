import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ProductCard = ({ product, selectedWeight, onWeightChange ,onProductClick}) => {
  const imageUrl = product.images && product.images.length > 0 ? product.images[0]?.url : '/path/to/fallback-image.jpg';
  const [notification, setNotification] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const navigate = useNavigate();

  // Automatically set the first available weight on mount
  useEffect(() => {
    
    if (!selectedWeight && product.availableWeights && product.availableWeights.length > 0) {
      onWeightChange(product.availableWeights[0]); // Set the first available weight
    }
  }, [product, selectedWeight, onWeightChange]);

  // Animation effect on mount
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 50);
    return () => clearTimeout(timer);
  }, []);

  const isSample = selectedWeight && product.availableWeights.filter(weight => weight.weight === "Sample").some(weight => weight.weight === selectedWeight.weight);

  // Handler for Add to Cart button
  const addToCart = async () => {
    const token = localStorage.getItem('authToken');
    if (!selectedWeight) {
      toast.error('Please select a weight option before adding to cart.');
      return false;
    }

    const weightPrice = selectedWeight.price;

    if(token){
      try {
        const cartResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/cart`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        const cartItems = cartResponse.data.cart?.products || [];
  
        const existingCartItem = cartItems.find(
          (item) => item.product?._id === product._id && item.weight === selectedWeight.weight
        );
        
        if (isSample && existingCartItem) {
          toast.error(`This sample (${product.name} ${selectedWeight.weight}) is already in your cart.`);
          return false;
        }
        
        if (existingCartItem && existingCartItem._id) {
          // Update the quantity and price of the existing item
          await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/cart/${existingCartItem._id}`,
            {
              quantity: existingCartItem.quantity + selectedQuantity,
              
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          toast.success(`${product.name} (${selectedWeight.weight}) has been added to your cart.`);
        } else {
          // Add the item to the cart
          await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/cart`,
            {
              productId: product._id,
              quantity: selectedQuantity,
              weight: selectedWeight.weight,
              price: selectedWeight.price, 
           
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        }
  
        toast.success(`${product.name} (${selectedWeight.weight}) has been added to your cart.`);
  
        return true; // Return success
      } catch (error) {
        console.error('Failed to add product to cart:', error.response?.data || error);
        toast.error(error.response?.data.message || 'An error occurred while adding to cart.');
        return false;
      }
    }

    else{

      try {
        const newItem = {
          _id: product._id,
          product: product._id,
          name:product.name,
          quantity: selectedQuantity,
          weight: selectedWeight.weight,
          price: selectedWeight.price,
          image: imageUrl, 
        };
    
        // Check if 'cartItems' exists in localStorage
        const existingCartItems = JSON.parse(localStorage.getItem('cartItems'));
    
        if (existingCartItems) {
          // 'cartItems' exists, so add the item by refering the _id
          // const existingCartItem = existingCartItems.find((item) => item._id === newItem._id);
          const existingCartItem = existingCartItems.find((item) => item._id === newItem._id && item.weight === selectedWeight.weight);    
          if (existingCartItem) {
            
            existingCartItem.quantity += newItem.quantity;
      
          } else {
            existingCartItems.push(newItem);
          }
          localStorage.setItem('cartItems', JSON.stringify(existingCartItems));
        } else {
          // 'cartItems' does not exist, create a new array with the new item
          localStorage.setItem('cartItems', JSON.stringify([newItem]));
        }
    
        toast.success(`${product.name} (${selectedWeight.weight}) has been added to your cart.`);
        return true; // Return success
      }

      catch (error) {
        console.error('Failed to add product to cart:', error);
        toast.error('An error occurred while adding to cart.');
      }


    }
  };

  const handleAddToCart = async (e) => {
    e.stopPropagation();
    const success = await addToCart();
    if (success) {
      // toast.success(`${product.name} (${selectedWeight.weight}) has been added to your cart.`);
    }
  };
  const handleBuyNow = async (e) => {
    e.stopPropagation();
    const success = await addToCart();
    if (success) {
      navigate('/cart'); // Navigate to cart page after successful addition
    }
  };

  // Handle quantity selection

 

  const goToProductDetail = () => {
    navigate(`/product?productId=${product._id}`);
  };
  return (
    <div 
      className={`  w-full h-full bg-[#222222] rounded-[10px] shadow-lg cursor-pointer transition-transform duration-1000 ease-in-out ${
        isVisible ? 'opacity-100 transform translate-y-0' : 'opacity-0 transform -translate-y-10'
      }`}
      onClick={goToProductDetail} 
    > 
    
    <div className='h-[220px] w-full p-[10px] '>
  <img src={imageUrl} alt={product.name} className="w-full h-full mb-4 rounded-[12px] object-cover" />
</div>

      
      <div className="px-[20px] md:w-[305px]">
        <h4 className="text-[24px] font-medium mt-4">{product.name}</h4>
        <p className="mb-[2px] text-lg sm:text-2xl text-[#ECBC56] text-[24px] font-medium">
          ₹{selectedWeight ? selectedWeight.price : product.price}
        </p>
      </div>

     
<div className="mt-4 px-[20px] md:w-full">
        <div className="grid grid-cols-3 gap-2"> 
          {product.availableWeights && product.availableWeights.length > 0 ? (
            product.availableWeights.map((weightOption) => (
              <button
                key={weightOption.weight}
                className={`h-auto w-full flex-grow py-[5px] md:p-1 rounded-[10px] bg-[#181818] text-gray-300 border-2 ${
                  selectedWeight && selectedWeight.weight === weightOption.weight
                    ? 'text-white border-[#ECBC56]'
                    : 'border-transparent'
                }`}
                onClick={(e) => {e.stopPropagation(); onWeightChange(weightOption); }}
              >
                {weightOption.weight}
              </button>
            ))
          ) : (
            <p className="text-gray-400">No weights available</p>
          )}
        </div>
      </div>

      

     
    <div className="mt-4 px-[20px] md:w-full">
        {!isSample && (
          <div className="grid grid-cols-2 gap-2">
            <h3
              className="text-[16px] md:text-[16px] pt-2 items-center  justify-center  text-gray-400 font-normal rounded-l-lg"
              htmlFor="quantity"
            >
              Select Quantity:
            </h3>
            <div className="flex items-center ">
              <button
                onClick={(e) => { e.stopPropagation(); setSelectedQuantity(prev => Math.max(1, prev - 1)); }} // Decrease quantity
                className="py-2 px-[19px] md:px-[17px] bg-[#181818] text-white rounded-l-lg"
              >
                -
              </button>
              <span className="py-2 px-[19px] bg-[#181818] text-white">{selectedQuantity}</span>
              <button
                onClick={(e) => { e.stopPropagation(); setSelectedQuantity(prev => prev + 1); }} // Increase quantity
                className="py-2 px-5 md:px-[17px] bg-[#181818] text-white rounded-r-lg"
              >
                +
              </button>
            </div>
          </div>
        )}
      </div>

     
            <div className="grid grid-cols-2 gap-2 mt-2 px-[10px] md:px-[20px] pb-[20px]"> {/* Changed to grid layout with 2 columns */}
        <button className="bg-[#181818] text-[16px] w-full h-auto border-2 border-[#0071E3] text-[#0071E3] font-semibold p-1 md:px-[20px] md:py-[10px] rounded-[5px] hover:bg-gray-700" onClick={handleAddToCart}>
          Add to Cart
        </button>
        <button onClick={handleBuyNow} className="bg-[#0071E3] text-white text-[16px] w-full h-auto  p-1 rounded-[5px] hover:bg-blue-900">
          Buy Now 
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
import React from "react";
import Hero from "../asstes/banner-hero.jpg";

const HeroSection = () => {
  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat md:h-[730px] h-[500px]"
      style={{ backgroundImage: ` url(${Hero}) ` }}
    >
      <div className="absolute inset-0  bg-black bg-opacity-25"></div> {/* Optional dark overlay */}
      <div className="relative   h-full flex items-center">
        {/* Text Section */}
        <div className="mt-12 md:mt-0 text-white w-[300px] md:w-[750px] px-[20px] md:px-[60px] z-10 ">
          {/* Heading */}
          <h2 className="">
            <span className="text-white  font-normal text-[25px] md:text-[44px]  inline-block   ">Discover the
            </span> <br />
            <div className="font-semibold leading-none md:text-[57px] text-[35px] bg-clip-text bg-primarygradient text-transparent">
              True Flavors with
            </div>
            <div className="font-semibold leading-none md:text-[57px] text-[35px] bg-clip-text bg-primarygradient text-transparent">
              No Additives
            </div>
          </h2>

          {/* Paragraph */}
          <p className="md:text-[25px] w-3/4 text-[16px] font-normal mt-6">
            Experience the pure taste of our handcrafted millets and spices. <br />
            Elevate your lifestyle with our preservative-free products.

          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
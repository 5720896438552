import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ProductCard from './ProductCard';
import Spinner from './Spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Slider from 'react-slick';
import ArrowButton from './ArrowButton';

const ProductComponent = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Access location for categoryName
  const categoryRefs = useRef([]); // Store references to category divs

  const [openCategoryIndices, setOpenCategoryIndices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [quantities, setQuantities] = useState({});
  const [categoriesWithProducts, setCategoriesWithProducts] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const sliderSettings = {
    
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <ArrowButton direction="left" isVisible />,
    nextArrow: <ArrowButton direction="right" isVisible />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          
        },
      },
    ],
  };

  useEffect(() => {
    const fetchCategoriesAndProducts = async () => {
      try {
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/categories`);
        const productsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/products/all`);

        const allProducts = productsResponse.data.products;
        allProducts.sort((a, b) => a.sortNumber - b.sortNumber);

        const fetchedCategories = categoriesResponse.data.categories.map(category => ({
          id: category._id,
          title: category.title,
          products: allProducts.filter(product => product.category === category._id),
        }));

        setCategoriesWithProducts(fetchedCategories);

        // Open categories with products by default
        setOpenCategoryIndices(fetchedCategories.map((_, index) => fetchedCategories[index].products.length > 0 ? index : null).filter(index => index !== null));
      } catch (error) {
        toast.error('Error fetching categories and products. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCategoriesAndProducts();
  }, []);

  useEffect(() => {
    // Automatically open the dropdown and scroll to the category if it matches `categoryName` from `location.state`
    if (location.state && location.state.categoryName && categoriesWithProducts.length > 0) {
      const targetIndex = categoriesWithProducts.findIndex(
        category => category.title === location.state.categoryName
      );

      if (targetIndex !== -1) {
        setOpenCategoryIndices(prev => [...prev, targetIndex]); // Open the dropdown
        categoryRefs.current[targetIndex].scrollIntoView({ behavior: 'smooth', block: 'start' }); // Scroll to the category
      }
    }
  }, [location.state, categoriesWithProducts]);

  const toggleCategory = (index) => {
    if (categoriesWithProducts[index].products.length > 0) {
      setOpenCategoryIndices(openCategoryIndices.includes(index)
        ? openCategoryIndices.filter(i => i !== index)
        : [...openCategoryIndices, index]
      );
    }
  };

  const handleAddToCart = (product) => {
    toast.success(`${product.name} has been added to your cart!`, {
      icon: (
        <div
          style={{
            backgroundColor: '#ECBC56',
            padding: '8px',
            borderRadius: '50%',
          }}
        >
          🛒
        </div>
      ),
    });
  };

  const handleWeightChange = (productId, weightOption) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: weightOption,
    }));
  };

  return (
    <div onMouseEnter={() => setIsHovered(true)}
         onMouseLeave={() => setIsHovered(false)} 
         className="bg-[#181818] text-white w-full h-full py-6 px-4  md:px-20 mb-12 ">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: '#ECBE56',
          color: 'black',
          display: 'flex',
          width: 'auto',
          padding: '10px',
        }}
        progressStyle={{
          backgroundColor: 'black',
        }}
        style={{
          position: 'fixed',
          top: '100px',
          right: '40px',
          zIndex: 9999,
        }}
      />

      {loading ? (
        <Spinner />
      ) : (
        categoriesWithProducts.map((category, index) => (
          <div
            key={category.id}
            ref={el => (categoryRefs.current[index] = el)} // Attach ref to each category div
            className="mb-6 relative"
          >
            <div
              className="bg-[#222222] flex justify-between items-center p-3 md:p-4 rounded cursor-pointer"
              onClick={() => toggleCategory(index)}
            >
            
              <div className="text-lg text-left md:text-2xl font-bold bg-clip-text bg-primarygradient text-transparent">
              {category.title}
            </div>
              <span className="text-xl text-gray-600">
                {openCategoryIndices.includes(index) ? <FaChevronUp /> : <FaChevronDown />}
              </span>
            </div>
            <div className='md:px-12'>


            {openCategoryIndices.includes(index) && (
              <>
                {/* <div className="w-full flex justify-end mt-4">
                  <div className="flex items-center space-x-2">
                    <label htmlFor={`sort-${index}`} className="text-sm sm:text-m">
                      Sort by
                    </label>
                    <select
                      id={`sort-${index}`}
                      className="bg-gray-800 text-white p-2 rounded w-30 md:w-36 h-10"
                    >
                      <option value="featured">Featured</option>
                      <option value="millets">Millets</option>
                    </select>
                  </div>
                </div> */}

                <Slider {...sliderSettings}>
                  {category.products.length > 0 ? (
                    category.products.map((product, productIndex) => (
                      <div
                        className="mt-8 w-[305px] h-full flex-shrink-0 px-0 pl-5 md:pl-0 md:px-4"
                        key={product._id}
                        style={{
                          animation: 'translateYEffect 1.5s ease forwards',
                          animationDelay: `${productIndex * 0.1}s`,
                          opacity: 0,
                        }}
                      >
                        <ProductCard
                          product={product}
                          selectedQuantity={quantities[product._id] || 1}
                          selectedWeight={quantities[product._id] || product.availableWeights?.[0]}
                          handleAddToCart={() => handleAddToCart(product)}
                          onWeightChange={(weightOption) => handleWeightChange(product._id, weightOption)}
                        />
                      </div>
                    ))
                  ) : null}
                </Slider>
              </>
            )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ProductComponent;

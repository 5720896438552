
import React from 'react';

const ArrowButton = ({ direction = "right", onClick, isVisible }) => (
    <button
      onClick={onClick}
      className={`arrow-button bg-white bg-opacity-90 text-gray-800 rounded-full p-2 shadow-md hover:shadow-lg transition duration-300 ease-in-out
        ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'} ${direction === 'left' ? 'left' : 'right'}`}
      style={{
     
        [direction === "left" ? 'left' : 'right'] : '-55px'
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className={`h-4 w-4 md:w-6 md:h-6 ${direction === "left" ? 'rotate-180' : ''}`} // Flip the arrow icon for the left direction
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
    </button>
  );

  export default ArrowButton;